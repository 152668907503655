<template>
    <div class="goods-page">
        <div class="tab-bar">
            <van-tabs v-model:active="tabActive" :animated="false" color="#DF5F0B" title-active-color="#DF5F0B">
                <van-tab replace title="待收货" to="/client/goods/unreceived" />
                <van-tab replace title="已收货" to="/client/goods/received" />
            </van-tabs>
        </div>
        <div class="goods-list">
            <div v-if="receiveList && receiveList.length > 0" class="list-container">
                <div v-for="(item, index) in receiveList" class="list-item">
                    <van-checkbox-group v-model="goodsChecked">
                        <van-checkbox :name="item.id" checked-color="#DF5F0B">
                            <div class="item-wrapper">
                                <p class="p1">{{ item.order.order_sn }}</p>
                                <p class="p2">数量：x {{ item.order.number }}</p>
                                <p class="p3" @click="toDetail(item.id)">查看详情</p>
                            </div>
                        </van-checkbox>
                    </van-checkbox-group>
                </div>
            </div>
            <van-empty v-else description="暂无数据" />
        </div>
        <van-button block class="goods-button" color="#DF5F0B" icon="sign" @click="onBatchReceive">
            一键收取选中货品
        </van-button>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { BATCH_GOODS_RECEIVE, GET_GOODS_RECEIVE_LIST } from "@/api/goods";
import usePublic from "@/hooks/usePublic";
import { Dialog, Toast } from "vant/lib/";

let { $route, $router, $store } = usePublic();

let tabActive = ref(0);

let goodsChecked = ref([]);

onMounted(() => {
    getDataList();
});

// 获取列表数据
let receiveList = ref([]);

let getDataList = () => {
    GET_GOODS_RECEIVE_LIST({
        type: "stay",
        limit: 10000
    }).then((res) => {
        receiveList.value = res.data.data.list;
    });
};

let onBatchReceive = () => {
    if (!goodsChecked.value || goodsChecked.value.length <= 0) {
        Toast({
            type: "fail",
            message: "请先选择订单"
        });

        return false;
    }

    Dialog.confirm({
        title: "提示",
        message: "确认收货？"
    }).then(() => {
        BATCH_GOODS_RECEIVE({ status: 1, ids: goodsChecked.value }).then((res) => {
            if (res.data.code === 200) {
                getDataList();
                Toast.success("批量收货成功");
            }
        });
    });
};

// 前往详情页
let toDetail = (id) => {
    console.log(id);
    $router.push({ name: "ClientGoodsReceiveDetail", params: { id } });
};
</script>

<style lang="scss" scoped>
.goods-page {
    min-height: 100vh;
    padding-bottom: 60px;
    background: #f0f0f0;

    .tab-bar {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    .goods-list {
        .list-container {
            width: 100%;
            margin: 10px 0 0 0;
            padding: 0 15px;
            background: #ffffff;

            .list-item {
                padding: 15px 0;
                border-bottom: 1px dashed #eeeeee;

                &:last-child {
                    border-bottom: none;
                }

                .item-wrapper {
                    width: 100%;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;

                    &:first-child {
                        margin-top: 0;
                    }

                    P {
                        &:last-child {
                            margin-left: auto;
                        }
                    }
                }

                :deep(.van-checkbox-group) {
                    width: 100%;

                    .van-checkbox {
                        .van-checkbox__label {
                            width: 100%;
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                p {
                    font-size: 12px;
                }

                .p1 {
                    width: 180px;
                    color: #000000;
                }

                .p2 {
                    color: #999999;
                }

                .p3 {
                    margin-left: auto;
                    color: #df5f0b;
                }
            }
        }
    }

    .goods-button {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        border-radius: 0;
    }
}
</style>
